<template>
	<div class="login_wrap">
		<div class="login-wrapper">
			<div class="bg-wrapper">
				<!-- 密码登录 -->
				<div class="user-wrapper">
					<el-form :model="loginForm_zz" :rules="rules" ref="loginRef" style="width: 340px;height: 100%">
						<el-form-item>
							<p class="login_title">商户管理平台</p>
						</el-form-item>
						<el-form-item style="margin-top: 50px;" prop="username">
							<el-input v-model="loginForm_zz.username" size="large" style="width: 100%;line-height: 50px"
								placeholder="请输入您的账号" ref="password" prefix-icon="el-icon-user"></el-input>
						</el-form-item>
						<el-form-item prop="password" style="margin-top: 20px">
							<el-input v-model="loginForm_zz.password" size="large" style="width: 100%; border: none"
								placeholder="请输入密码" ref="password" prefix-icon="el-icon-lock"
								@keyup.enter.native="handleLogin_zz" show-password></el-input>
						</el-form-item>
						<el-form-item prop="password" style="margin-top: 20px">
							<el-input v-model="loginForm_zz.code" size="large" style="width: 100%; border: none"
								placeholder="请输入谷歌验证码" ref="password" prefix-icon="el-icon-lock"></el-input>
						</el-form-item>
						<el-form-item style="margin-top: 40px" align="center">
							<el-button type="primary" style="width: 100%;height: 46px" size="large" :loading="isSaveing"
								@click="handleLogin_zz">登录
							</el-button>
						</el-form-item>
					</el-form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		userLoginByAccount
	} from "@/api/login";
	import {
		getDataTimeSec
	} from "@/utils/index";

	export default {
		name: "login",
		data() {
			return {
				loginForm_zz: {
					username: "test123",
					password: "a123456",
					code: "",
				},
				isSaveing: false,
				rules: {
					username: [{
						required: true,
						message: '请输入账号',
						trigger: 'blur'
					}],
					password: [{
						required: true,
						message: '请输入密码',
						trigger: 'blur'
					}]
				},
			};
		},
		mounted() {

		},

		methods: {
			//登录
			handleLogin_zz() {
				this.$refs.loginRef.validate(async (valid) => {
					if (valid) {
						// this.isSaveing = true;
						let ajax = {
							terminal: 5,
							scene: 1,
							account: this.loginForm_zz.username,
							password: this.loginForm_zz.password,
							google:this.loginForm_zz.code,
						};
						userLoginByAccount(ajax)
							.then((res) => {
								console.log(res)
								if (res.code === 1) {
									this.isSaveing = false;
									localStorage.setItem("token", res.data.token);
									localStorage.setItem("name", res.data.nickname);
									localStorage.setItem("mchId", res.data.mchId);
									localStorage.setItem("avatar", res.data.avatar);
									localStorage.setItem("type", res.data.type);
									this.$router.push("/dashboard");
									this.$message.success('登录成功');
								} else {
									this.$message.error(res.msg);
									return;
								}
							})
							.catch((err) => {
								console.log(err)
								this.$message.error(err.msg);
							});
					}
				})
			},
		},
	};
</script>

<style lang="scss" scoped>
	.login_wrap {
		width: 100%;
		height: 100%;
	}

	/deep/ .el-input__inner {
		height: 40px !important;
	}

	.login-wrapper {
		position: relative;
		width: 100%;
		height: 100%;
		background-image: url("../../assets/images/login_bg.png");
		background-size: cover;
		display: flex;
		flex-direction: row;
		z-index: 1;
		justify-content: center;
		align-items: center;
	}

	.switch_img {
		position: absolute;
		top: 8px;
		right: 8px;
	}

	.bg-wrapper:hover {
		box-shadow: 0px 0px 40px rgba(39, 148, 175, 0.1);
		transition: all 0.4s;
	}

	.bg-wrapper .el-input__inner {
		border: none;
	}
</style>